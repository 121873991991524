import { IUserInfo } from "./UserInfo";
import { IConfiguration } from "./Configuration";
import { ISessionPolicyInfo } from '../business/LicensingService';
import { IPalette } from 'office-ui-fabric-react';

export interface IPageContextInfo {
    pageErrors: IErrorInfo[];
    formType: string;
    formData: any;
    basePath: string;
    pagePath: string;
    configuration?: IConfiguration;
}

export interface ISessionInfo {
    sessId: string;
    isLogin: boolean;
    isRegistered: boolean;
    currentUser?: IUserInfo;
    userId?: string;
    userType?: UserType;
    userLocale?: string;
    personalLocation?: string;
    tenantUrl: string;
    licenses: ISessionPolicyInfo;
    roles: string[];
    groups: string[];
    loginAllowed: boolean;
    fabricColor: IPalette;
    organizationName?: string;
    renderDeprecatedLeftBarInSPO: boolean;
    doNotOpenSPNewsInMSTeamsDialog: boolean;
}

export interface IContextAndSessionInfo extends IPageContextInfo, ISessionInfo {
}

export enum UserType {
    UnknownAccount = 0,
    PrivateAccount = 1,
    WorkAccount = 2
}

export interface IErrorList {
    errors: IErrorInfo[];
}

export interface IErrorInfo {
    type: string;
    code?: string;
    message: string;
    details?: string;
};

