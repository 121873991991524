import * as React from 'react';
import styles from "./QuickActionsStyle.scss";
import QuickActionsStrings from './loc/QuickActionsStrings';
import { SmartPortal } from '../../SmartPortal';
import { Panel, PanelActions, Action } from "../../common/controls/Panel";
import { GraphBatchService } from '../../common/business/GraphBatchService';
import { ActionButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { UserType } from '../../common/models/SessionInfo';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { SiteRequestDialog } from '../provisioning/SiteRequestDialog';
import { FileUploadDialog } from '../fileupload/FileUploadDialog';
import { LicensingService } from '../../common/business/LicensingService';
import { AppPolicy } from '../../common/models/AppPolicy';
import { Role } from '../../common/business/RoleService';
import { SharePointPage } from '../../appstart/spapp/sharepoint/SharePointPage';

export interface IQuickActionsPanelProps {
    renderInRightPanel?: boolean;
    headerText?: string;
    expandLink?: string;
    closeAction?: () => Promise<void>;
}

export interface IQuickActionsPanelState {
    showCreateNews: boolean;
    siteUrl: string;
}

export class QuickActionsPanel extends React.Component<IQuickActionsPanelProps, IQuickActionsPanelState> {
    private rendered: boolean;

    constructor(props: IQuickActionsPanelProps, state: IQuickActionsPanelState) {
        super(props);

        this.rendered = false;
        this.state = {
            showCreateNews: false,
            siteUrl: ""
        };
    }

    private async checkNewsSite() {
        if (ServiceLoader.GetService(LicensingService).checkPolicy(AppPolicy.FeatureMyWorkplace)) {
            let siteUrl = null;
            const currentPage = SmartPortal.currentPage;
            if (currentPage instanceof SharePointPage) {
                const spPageContext = (currentPage as SharePointPage).getCurrentPageContext();
                siteUrl = spPageContext.site.absoluteUrl;
            }
            if (!siteUrl) {
                siteUrl = SmartPortal.currentSession.tenantUrl;
                try {
                    const orgSites = (await ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                        method: "GET",
                        version: "v1.0",
                        siteUrl: SmartPortal.currentSession.tenantUrl,
                        restUrl: "OrgNews/SitesReference"
                    },
                        true)).d.SitesReference.results;
                    if (orgSites.length > 0) {
                        siteUrl = (await ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                            method: "GET",
                            version: "v1.0",
                            siteUrl: SmartPortal.currentSession.tenantUrl,
                            restUrl: "SPSiteManager/SiteUrl('" + orgSites[0].SiteId + "')"
                        },
                            true)).d.SiteUrl;
                    }
                } catch (e) {
                    siteUrl = SmartPortal.currentSession.tenantUrl;
                }
            }
            const isActive = !!(await ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                version: "v1.0",
                siteUrl: siteUrl,
                restUrl: "Web/Features/GetByID('B6917CB1-93A0-4B97-A84D-7CF49975D4EC')", // SitePages feature
            },
                true)).d.DefinitionId;
            if (isActive) {
                let relativeSiteUrl = (new URL(siteUrl)).pathname;
                relativeSiteUrl = relativeSiteUrl.endsWith("/") ? relativeSiteUrl : (relativeSiteUrl + "/");
                const effectiveBasePermissions = (await ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                    method: "GET",
                    version: "v1.0",
                    siteUrl: siteUrl,
                    restUrl: "Web/GetList('" + relativeSiteUrl + "SitePages')/EffectiveBasePermissions", // SitePages list
                },
                    true)).d.EffectiveBasePermissions;
                const isAllowed = this.hasPermission(parseInt(effectiveBasePermissions.Low), parseInt(effectiveBasePermissions.High), 2); // AddListItems base permission
                if (isAllowed) {
                    this.setState({
                        showCreateNews: true,
                        siteUrl: siteUrl
                    });
                }
            }
        }
    }

    private hasPermission(low: number, high: number, bitIndex: number): boolean {
        let sequence = low;
        if (bitIndex >= 32) {
            sequence = high;
            bitIndex -= 32;
        }
        return ((2 ^ (bitIndex - 1)) | sequence) == sequence;
    }

    private getCreateSharePointNewsUrl(): string {
        let url = this.state.siteUrl ? this.state.siteUrl : SmartPortal.currentSession.tenantUrl;
        if (!url.match(/\/$/))
            url += "/";
        url += "_layouts/15/CreateSitePage.aspx?source=" + encodeURIComponent(url + "_layouts/15/news.aspx") + "&promotedState=1";
        return url;
    }

    public componentDidMount(): void {
        if (!this.rendered) {
            this.rendered = true;
            this.checkNewsSite();

            //Promise.all([
            //    ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
            //        method: "GET",
            //        version: "v1.0",
            //        siteUrl: SmartPortal.currentSession.tenantUrl,
            //        restUrl: "web/features/GetByID('B6917CB1-93A0-4B97-A84D-7CF49975D4EC')", // SitePages feature
            //    }),
            //    ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
            //        method: "GET",
            //        version: "v1.0",
            //        siteUrl: SmartPortal.currentSession.tenantUrl,
            //        restUrl: "web/EffectiveBasePermissions",
            //    }),

            //]).then((reqRes) => {
            //    let isActive = !!reqRes[0].d.DefinitionId;
            //    let isAllowed = this.hasPermission(parseInt(reqRes[1].d.EffectiveBasePermissions.Low), parseInt(reqRes[1].d.EffectiveBasePermissions.High), 2); // AddListItems base permission

            //    this.setState({
            //        showCreateNews: isActive && isAllowed
            //    });
            //});
        }
    }
    
    public render(): React.ReactElement<IQuickActionsPanelProps> {
        const actions: {
            text: string;
            description: string;
            icon: string;
            onClick?: (evt: any) => void;
            href?: string;
        }[] = [];

        const licensingService = ServiceLoader.GetService(LicensingService);

        if (SmartPortal.currentSession.personalLocation && licensingService.checkPolicy(AppPolicy.FeatureMyWorkplace)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_UploadPersonalDocument,
                description: QuickActionsStrings.QuickActions_UploadPersonalDocumentDescription,
                icon: "Upload",
                onClick: (evt) => this.onUploadDocumentClick(evt, "OneDrive")
            });
        }

        if (SmartPortal.currentSession.userType === UserType.WorkAccount && !(SmartPortal.currentPage instanceof SharePointPage) && licensingService.checkPolicy(AppPolicy.FeatureMyWorkplace)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_UploadDocument,
                description: QuickActionsStrings.QuickActions_UploadDocumentDescription,
                icon: "Upload",
                onClick: (evt) => this.onUploadDocumentClick(evt, "SharePoint")
            });
        }

        if (SmartPortal.currentSession.userType === UserType.WorkAccount && (SmartPortal.currentPage instanceof SharePointPage) && licensingService.checkPolicy(AppPolicy.FeatureMyWorkplace)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_UploadDocumentHere,
                description: QuickActionsStrings.QuickActions_UploadDocumentHereDescription,
                icon: "Upload",
                onClick: (evt) => this.onUploadDocumentClick(evt, "SharePoint")
            });
        }

        if (SmartPortal.currentSession.userType === UserType.WorkAccount && this.state.showCreateNews && !(SmartPortal.currentPage instanceof SharePointPage)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_CreateSharePointNews,
                description: QuickActionsStrings.QuickActions_CreateSharePointNewsDescription,
                icon: "News",
                href: this.getCreateSharePointNewsUrl()
            });
        }

        if (SmartPortal.currentSession.userType === UserType.WorkAccount && this.state.showCreateNews && (SmartPortal.currentPage instanceof SharePointPage)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_CreateSharePointHereNews,
                description: QuickActionsStrings.QuickActions_CreateSharePointNewsHereDescription,
                icon: "News",
                href: this.getCreateSharePointNewsUrl()
            });
        }

        if (SmartPortal.currentSession.userType === UserType.WorkAccount && licensingService.checkPolicy(AppPolicy.FeatureProvisioning) && SmartPortal.isUserInRole(Role.Requester)) {
            actions.push({
                text: QuickActionsStrings.QuickActions_RequestSharePointSite,
                description: QuickActionsStrings.QuickActions_RequestSharePointSiteDescription,
                icon: "System",
                onClick: (evt) => this.onRequestSiteClick(evt)
            });
        }

        return (
            this.props.renderInRightPanel ?
                <div className={styles.QuickActionsContainer}>
                    <h2>{QuickActionsStrings.QuickActions_Header}</h2>
                    <ul>
                        {
                            actions.map(action =>
                                <li>
                                    <ActionButton className={styles.QuickActionButton} iconProps={{ iconName: action.icon }}
                                        onClick={action.onClick}
                                        href={action.href}>
                                        <div>
                                            <div className={styles.QuickActionButtonText}>{action.text}</div>
                                            <div className={styles.QuickActionButtonDescription}>{action.description}</div>
                                        </div>
                                    </ActionButton>
                                </li>)
                        }
                    </ul>
                </div> :
                <Panel className={styles.PanelBody + " " + styles.QuickActionsPanel} headerText={this.props.headerText ? this.props.headerText : QuickActionsStrings.QuickActions_Title} infoTooltip={QuickActionsStrings.QuickActions_Info}
                    actions={[this.props.expandLink ? Action.Expand : Action.None, this.props.closeAction ? Action.Close : Action.None]} onAction={(action) => {
                        if (action === Action.Close) {
                            return this.props.closeAction();
                        }
                        if (action === Action.Expand) {
                            SmartPortal.navigateTo(this.props.expandLink);
                        }
                    }}>
                    <div className={styles.QuickActionsPanelActions}>
                        {
                            actions.map(action =>
                                <div className={styles.QuickActionsPanelAction}>
                                    <ActionButton className={styles.QuickActionButton} iconProps={{ iconName: action.icon }}
                                        onClick={action.onClick}
                                        href={action.href}>
                                        <div>
                                            <div className={styles.QuickActionButtonText}>{action.text}</div>
                                            <div className={styles.QuickActionButtonDescription}>{action.description}</div>
                                        </div>
                                    </ActionButton>
                                </div>)
                        }
                    </div>
                </Panel>
        );
    }

    private renderLoadingSplash(): React.ReactNode {
        return <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />;
    }

    private onUploadDocumentClick(evt: any, type: "OneDrive" | "SharePoint") {
        evt.preventDefault();
        return new Promise<void>((resolve) => {
            const contentKey = `upload${type}DocumentDialog`;

            SmartPortal.currentPage.setDynamicContent(contentKey, FileUploadDialog.GetFileUploadDialog(type, contentKey, resolve, this.renderLoadingSplash));
        });
    }

    private onRequestSiteClick(evt: any) {
        evt.preventDefault();
        return new Promise<void>((resolve) => {
            const contentKey = `requestSiteDialog`;
            SmartPortal.currentPage.setDynamicContent(contentKey, <SiteRequestDialog key={contentKey} onReturn={() => {
                SmartPortal.currentPage.setDynamicContent(contentKey, null);
                resolve();
            }} />);
        });
    }
}
