import * as React from 'react';
import styles from "./PageStyle.scss";
import dialogStyles from "../../common/controls/Dialog/DialogStyle.scss";
import { Callout, DirectionalHint, Icon, Persona, PersonaSize, SearchBox, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SmartPortal } from '../../SmartPortal';
import { UserActionMenu } from './UserActionMenu';
import { Dialog } from '../../common/controls/Dialog/Dialog';
import { Action } from "../../common/controls/Panel";
import { QuickActions } from './QuickActions';
import PageStrings from './loc/PageStrings';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { LicensingService } from '../../common/business/LicensingService';
import { AppPolicy } from '../../common/models/AppPolicy';
import { GuidedTourService } from '../../common/business/GuidedTourService';
import { GraphBatchService } from '../../common/business/GraphBatchService';
import { renderFriendlyDate } from '../../common/utils/DateHandler';

const ProfileEditForm = React.lazy(() => import(/* webpackChunkName: "session" */ '../session/ProfileEditForm'));

export interface IPortalTopBarProps {
    onNavToggle?(): void;
}

export interface IPortalTopBarState {
    isUserActionVisible: boolean;
    showSearchResults: boolean;
    search: string;
    searching: boolean;
    persons?: {
        companyName: string;
        department: string;
        displayName: string;
        id: string;
        jobTitle: string;
        officeLocation: string;
        phones: {
           number: string;
           type: string; 
        }[];
        scoredEmailAddresses: {
            address: string;
            relevanceScore: number;
            selectionLikelihood: string;
        }[];
        userPrincipalName: string;
    }[];
    sites?: {
        createdDateTime: Date;
        displayName: string;
        id: string;
        lastModifiedDateTime: Date;
        name: string;
        webUrl: string;
    }[];
    documents?: {
        createdBy: {
            user: {
                displayName: string;
            }
        };
        createdDateTime: Date;
        id: string;
        lastModifiedBy: {
            user: {
                displayName: string;
            }
        };
        lastModifiedDateTime: Date;
        name: string;
        parentReference: {
            driveId: string;
            id: string;
            sharepointIds: {
                listId: string;
                listItemId: string;
                listItemUniqueId: string;
            };
            siteId: string;
        };
        size: number;
        webUrl: string;
    }[];
}

export class PortalTopBar extends React.Component<IPortalTopBarProps, IPortalTopBarState> {
    private isQuickActionsVisible: boolean;

    constructor(props: IPortalTopBarProps, state: IPortalTopBarState) {
        super(props);

        this.state = {
            isUserActionVisible: false,
            showSearchResults: false,
            search: "",
            searching: false
        };
    }

    public componentDidMount(): void {

    }

    private search(search: string): void {
        this.setState({ searching: true, search: search }, () => {
            const reqSvc = ServiceLoader.GetService(GraphBatchService);
            Promise.all([
                reqSvc.addGraphRequest({
                    method: "GET",
                    path: "me/people?$top=3&$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'" + decodeURIComponent(this.state.search ? `&$search="${this.state.search}"` : ""),
                    version: "v1.0"
                }),
                reqSvc.addGraphRequest({
                    method: "POST",
                    path: "search/query",
                    version: "v1.0",
                    data: {
                        "requests": [
                            {
                                "entityTypes": [
                                    "site"
                                ],
                                "query": {
                                    "queryString": this.state.search ? this.state.search : "*"
                                },
                                "size": 3
                            }
                        ]
                    }
                }),
                reqSvc.addGraphRequest({
                    method: "POST",
                    path: "search/query",
                    version: "v1.0",
                    data: {
                        "requests": [
                            {
                                "entityTypes": [
                                    "driveItem"
                                ],
                                "query": {
                                    "queryString": (this.state.search ? this.state.search : "*") + " AND ContentTypeId:0x0101*"
                                },
                                "size": 3,
                                "sharePointOneDriveOptions": {
                                    "includeContent": "privateContent,sharedContent"
                                }
                            }
                        ]
                    }
                })
            ]).then(responses => {
                this.setState({ 
                    searching: false, 
                    persons: responses[0]?.value,
                    sites: responses[1]?.value[0]?.hitsContainers[0]?.hits?.map(h => h.resource),
                    documents: responses[2]?.value[0]?.hitsContainers[0]?.hits?.map(h => h.resource)
                });
            });
        });
    }

    public render(): React.ReactElement<IPortalTopBarProps> {
        let currentUser = SmartPortal.currentUser;
        return (
            <div className={styles.PortalHeader}>
                <div className={styles.PortalHeaderBar}>
                    <div className={styles.PortalNavLeft}>
                        {this.props.onNavToggle ?
                            <div className={styles.PortalNavButton + " " + styles.PortalNavToggle} onClick={(evt) => {
                                this.props.onNavToggle();
                            }}>
                                <div className={styles.PortalNavToggleIcon}>
                                    <Icon iconName="GlobalNavButton" className={styles.NavToggleIcon} />
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className={styles.PortalLogo}>
                        <div className={styles.LogoContainer}>
                            <a href="/"><img src="/Theming/Logo" alt="SmartPortal365" /></a>
                        </div>
                    </div>
                    <div className={styles.PortalSearch}>
                        <SearchBox className={styles.PortalSearchBox} value={this.state.search} placeholder={PageStrings.SearchPlaceholder} onFocus={() => {
                            if(this.state.showSearchResults !== true) {
                                this.setState({ showSearchResults: true }, () => {
                                    this.search(this.state.search);
                                    SmartPortal.currentPage.addPageOverlay({
                                        overlayName: "PortalSearchBox",
                                        contentSelector: [
                                            "." + styles.PortalSearch,
                                            "." + styles.PortalSearchBox,
                                            "." + styles.PortalSearchBox + " .ms-SearchBox-field",
                                            "." + styles.PortalSearchCallout
                                        ],
                                        dismissCallback: () => {
                                            this.setState({
                                                showSearchResults: false
                                            });
                                            return true;
                                        }
                                    });
                                });
                            }
                        }} onChange={(evt, search) => {
                            this.search(search);
                        }} />
                        {this.state.showSearchResults ?
                            <Callout className={styles.PortalSearchCallout} target={`.${styles.PortalSearchBox}`} alignTargetEdge={true} directionalHint={DirectionalHint.bottomAutoEdge} directionalHintFixed={true} isBeakVisible={false} gapSpace={2} calloutMaxWidth={500}>
                                {this.state.searching ? <Spinner /> : <>
                                    {this.state.persons && this.state.persons.length > 0 ? <div className={styles.PortalSearchResults}>
                                        <div className={styles.PortalSearchResultHeader}>{PageStrings.SearchResult_Persons}</div>
                                        {this.state.persons.map(p => <div className={styles.PortalSearchResult} onClick={() => {
                                            SmartPortal.navigateTo(`${SmartPortal.currentSession.tenantUrl}/_layouts/15/sharepoint.aspx?pp=${decodeURIComponent(`${p.id}|${p.userPrincipalName}`)}&v=${decodeURIComponent("search/overview")}`);
                                        }}>
                                            <Persona
                                                size={PersonaSize.size32}
                                                imageUrl={"/api/UserPhoto/" + p.userPrincipalName + "?size=48x48"}
                                                text={p.displayName}
                                                secondaryText={p.jobTitle}
                                                showSecondaryText={true}
                                                showInitialsUntilImageLoads={true}
                                            />
                                        </div>)}
                                    </div> : null}
                                    {this.state.sites && this.state.sites.length > 0 ? <div className={styles.PortalSearchResults}>
                                        <div className={styles.PortalSearchResultHeader}>{PageStrings.SearchResult_Sites}</div>
                                        {this.state.sites.map(s => <div className={styles.PortalSearchResult} onClick={() => {
                                            SmartPortal.navigateTo(`${s.webUrl}`);
                                        }}>
                                            <Persona
                                                size={PersonaSize.size32}
                                                text={s.displayName}
                                                secondaryText={renderFriendlyDate(s.lastModifiedDateTime)}
                                                showSecondaryText={true}
                                            />
                                        </div>)}
                                    </div> : null}
                                    {this.state.documents && this.state.documents.length > 0 ? <div className={styles.PortalSearchResults}>
                                        <div className={styles.PortalSearchResultHeader}>{PageStrings.SearchResult_Documents}</div>
                                        {this.state.documents.map(d => <div className={styles.PortalSearchResult} onClick={() => {
                                            SmartPortal.navigateTo(`${d.webUrl}?web=1`);
                                        }}>
                                            <Persona
                                                size={PersonaSize.size32}
                                                text={d.name}
                                                secondaryText={renderFriendlyDate(d.lastModifiedDateTime)}
                                                showSecondaryText={true}
                                            />
                                        </div>)}
                                    </div> : null}
                                    <div className={styles.PortalSearchMore} onClick={() => {
                                        SmartPortal.navigateTo(`${SmartPortal.currentSession.tenantUrl}/_layouts/15/sharepoint.aspx?q=${decodeURIComponent(this.state.search ? this.state.search : "*")}&v=${decodeURIComponent("search")}`);
                                    }}>{PageStrings.SearchResult_ShowMore}</div>
                                </>}
                            </Callout> : null
                        }
                    </div>
                    <div className={styles.PortalNavRight}>
                        <div className={styles.PortalNavButton + " " + styles.PortalNavQuickActions} onClick={(evt) => {
                            if (this.isQuickActionsVisible)
                                return;
                            this.onQuickActionsClick(evt).then(() => {
                                this.isQuickActionsVisible = false;
                            });
                            this.isQuickActionsVisible = true;
                        }}>
                            <div className={styles.PortalNavQuickActionsIcon}>
                                <Icon iconName="CirclePlus" className={styles.QuickActionsIcon} />
                            </div>
                        </div>
                        <div className={styles.PortalNavButton + " " + styles.PortalNavUser} onClick={(evt) => this.onUserActionClick(evt)}>
                            <div className={styles.PortalNavUserPhoto}>
                                <Persona text={currentUser.name} secondaryText={currentUser.mail} imageUrl="/Session/UserPhoto" size={PersonaSize.size32} imageAlt={currentUser.name} hidePersonaDetails />
                            </div>
                        </div>
                        <div className={styles.PortalNavUserMenu + (this.state.isUserActionVisible ? (" " + styles.UserMenuVisible) : "")}>
                            <UserActionMenu showPersona={true} menuItems={[
                                {
                                    text: PageStrings.UserAction_EditProfile,
                                    onClick: (evt) => {
                                        this.onProfileEditClick(evt).then(() => {
                                            this.setState({
                                                isUserActionVisible: false
                                            });
                                        });
                                    }
                                },
                                {
                                    text: PageStrings.UserAction_Reset_Guided_Tour,
                                    onClick: async (evt) => {
                                        try {
                                            var confirmed = await Dialog.showConfirmDialog(PageStrings.UserAction_Reset_Guided_Tour_Confirm_Title, PageStrings.UserAction_Reset_Guided_Tour_Confirm_Content);
                                            if (confirmed === true) {
                                                await GuidedTourService.resetGuidedTours();
                                            }
                                        } catch { }
                                        finally {
                                            this.setState({
                                                isUserActionVisible: false
                                            });
                                        }
                                    }
                                },
                                ,
                                {
                                    text: PageStrings.UserAction_Logout,
                                    link: "/Session/Logout"
                                },
                                {
                                    text: PageStrings.format(PageStrings.UserAction_Version, SmartPortal.appBuildInfo.version, ServiceLoader.GetService(LicensingService).checkPolicy(AppPolicy.FeatureProfessional) ? PageStrings.UserAction_ProEdition : ServiceLoader.GetService(LicensingService).checkPolicy(AppPolicy.FeatureProvisioning) ? PageStrings.UserAction_ProvisioningEdition : PageStrings.UserAction_FreeEdition),
                                    className: styles.UserActionVersion
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderLoadingSplash(): React.ReactNode {
        return <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />;
    }

    public onUserActionClick(evt: React.MouseEvent) {
        let setVisible = !this.state.isUserActionVisible;
        if (setVisible) {
            SmartPortal.currentPage.addPageOverlay({
                overlayName: "UserActionMenu",
                contentSelector: [
                    "." + styles.PortalNavUserMenu,
                    "." + styles.PortalNavButton + "." + styles.PortalNavUser,
                    "." + dialogStyles.Dialog
                ],
                dismissCallback: () => {
                    this.setState({
                        isUserActionVisible: false
                    });
                    return true;
                }
            });
        }

        this.setState({
            isUserActionVisible: setVisible
        });
    }

    public onProfileEditClick(evt: React.MouseEvent): Promise<void> {
        return new Promise((resolve) => {
            const contentKey = "profileEditDialog";

            SmartPortal.currentPage.setDynamicContent(contentKey, (
                <Dialog key={contentKey} showLogo={true} actions={[Action.Close]} onAction={(action) => {
                    if (action === Action.Close) {
                        SmartPortal.currentPage.setDynamicContent(contentKey, null);
                    }
                    return Promise.resolve();
                }}>
                    <React.Suspense fallback={this.renderLoadingSplash()}>
                        <ProfileEditForm onReturn={() => {
                            SmartPortal.currentPage.setDynamicContent(contentKey, null);
                            resolve();
                        }} />
                    </React.Suspense>
                </Dialog>
            ));
        });
    }

    public onQuickActionsClick(evt: React.MouseEvent): Promise<void> {
        return new Promise((resolve) => {
            SmartPortal.currentPage.setRightPanel({
                content: <QuickActions />,
                onDispose: () => {
                    resolve();
                },
                selectors: ["." + styles.PortalNavQuickActions]
            });
        });
    }
}
